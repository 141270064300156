import React from "react"
import {Button, Form, Icon, Input} from 'antd';
import {post} from "../../services/api";
import language from "../../../data/language.yaml";
import {Location} from "@reach/router";
import {navigate} from "gatsby";
import {LocaleContext} from "../../context/LocaleContext";

const { TextArea } = Input;

class MemberChangeForm extends React.Component {
    state = {
        allowSubmit: true,
        errors: [],
        showForm: true
    }

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    onSubmitCallback = (error, response) => {
        if (typeof(response) === 'object') {
            if (response.data.status === 'error') {
                this.setState({allowSubmit: true, errors: response.data.errors})
            } else if (response.data.status === 'success' && response.data.errors.length === 0) {
                this.setState({allowSubmit: false, showForm: false});
            }
        } else {
            this.setState({ allowSubmit: true, errors: ['There was a problem submitting your request. Please try again later.']});
        }
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(this.onValidateCallback);
    }

    onValidateCallback = (errors, values) => {
        if (errors === null) {
            this.setState({allowSubmit: false});
            post('/wp-json/brew/v1/member/update/', this.props.form.getFieldsValue(), this.onSubmitCallback);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        let lang = language.member_change;
        let current = this.context.locale;

        let content = false;
        if (this.state.showForm) {
            content = <Form onSubmit={this.onSubmit}>
                { this.state.errors && (
                    <ul>
                        {this.state.errors.map((error, index) => (<li key={"error-" + index}>{error}</li>))}
                    </ul>
                )}
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: lang.username.error[current] }],
                    })(
                        <Input
                            placeholder={lang.username[current]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: lang.password.error[current] }],
                    })(
                        <Input.Password
                            placeholder={lang.password[current]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('changes', {
                        rules: [{ required: true, message: lang.changes.errors.required[current]},
                            {min: 20, message: lang.changes.errors.min[current] }],
                    })(
                        <TextArea
                            placeholder={lang.changes[current]}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="btn btn-secondary" disabled={!this.state.allowSubmit}>
                        <span>{lang.submit[current]}</span>
                        <span className="icon arrow"></span>
                    </Button>
                </Form.Item>
            </Form>
        } else {
            content = <Location>
                {({location}) => {
                    let path = current === 'en' ? 'thank-you' : 'diolch';
                    navigate(location.pathname + path + '/');
                }}
            </Location>
        }

        return content;
    }
}

MemberChangeForm.contextType = LocaleContext;

export default Form.create()(MemberChangeForm);
